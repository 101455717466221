import { useEffect } from 'react'

import { usePathname, useRouter } from '../vendor/next'

export const useRedirectToPostSignupTermsConsent = ({
  shouldAcceptTerms,
}: {
  shouldAcceptTerms: boolean
}) => {
  const { replace } = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    if (pathname.includes('/post-signup-terms-and-conditions')) return
    if (shouldAcceptTerms) {
      replace('/post-signup-terms-and-conditions?returnTo=' + window.location.href)
    }
  }, [shouldAcceptTerms, replace, pathname])
}
