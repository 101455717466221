import { graphql } from '@qasa/graphql'

export const GET_CRONOFY_ELEMENT_DATA = graphql(`
  query CronofyElementData($homeId: ID!, $origin: String!) {
    cronofyElementData(homeId: $homeId, origin: $origin) {
      dataCenter
      endTime
      origin
      permissions
      requiredDuration
      startTime
      subs
      token
    }
  }
`)
