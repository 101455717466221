'use client'

import { useCallback, useEffect, useRef } from 'react'
import { theme } from '@qasa/qds-ui'
import { useLazyQuery } from '@apollo/client'

import './styling-overrides.css'
import { getCurrentLanguage } from '../../utils/i18n'

import type { CronofyDateTimePickerProps } from './date-time-picker.types'
import { GET_CRONOFY_ELEMENT_DATA } from './cronofy-element-token.gql'

// NOTE: This is a wrapper around the Cronofy DateTimePicker
// https://docs.cronofy.com/developers/ui-elements/date-time-picker/

// NOTE: https://docs.cronofy.com/developers/ui-elements/date-time-picker/#param-styles.colors
const CRONOFY_COLOR_OVERRIDES = {
  black: theme.colors.core.black, // defaults to #000000
  white: theme.colors.core.white, // defaults to #ffffff
  neutralDark: theme.colors.core.brownDark, // defaults to #6f6f6f
  neutral: theme.colors.core.brown, // defaults to #999999
  neutralLight: theme.colors.core.brownLight, // defaults to #e4ebf2,
  button: theme.colors.bg.brandPrimary, // button background color defaults to #15B3D6
  buttonConfirm: theme.colors.bg.brandPrimary, // buttonConfirm background color defaults to #e2fac8,
  buttonConfirmText: theme.colors.text.onBrandPrimary, // buttonConfirm text color defaults to #000000
  buttonHover: theme.colors.bg.brandPrimaryHover, // button background color when hovered defaults to #15B3D6
  buttonText: theme.colors.text.onBrandPrimary, // button text color defaults to #000000
  buttonTextHover: theme.colors.text.onBrandPrimary, // button text color when hovered defaults to #000000
  buttonActive: theme.colors.bg.brandPrimaryActive, // button background when selected/active defaults to #15B3D6
  buttonActiveText: theme.colors.text.onBrandPrimary, // button text color when a button is active/selected defaults to #000000
}

// NOTE: Unfortunately, cronofy doesn't provide types, so we have to define them ourselves
// Related issue: https://github.com/cronofy/cronofy-node/issues/96
export type CronofyTimeSlot = {
  start: string
  end: string
  participants: { sub: string }[]
}

type CronofyNotification = {
  notification: {
    type: string
    slot?: CronofyTimeSlot
    tzid?: string
  }
}

type CronofyDateTimePicker = {
  refresh: (options: LegitimateAny) => void
}

export function CronofyDateTimePicker({
  homeId,
  onSelectedSlot,
  // Used to force a refresh of the DateTimePicker
  refreshKey,
  config,
  locale,
}: CronofyDateTimePickerProps) {
  const CronofyElements = useRef<LegitimateAny | null>(null)
  const cronofyDatetimePicker = useRef<CronofyDateTimePicker | null>(null)
  const currentLang = getCurrentLanguage()

  const [getElementToken, { data: elementTokenData }] = useLazyQuery(GET_CRONOFY_ELEMENT_DATA)

  const initDateTimePicker = useCallback(() => {
    if (!elementTokenData) {
      return
    }

    const { subs, token, dataCenter, requiredDuration, startTime, endTime } =
      elementTokenData.cronofyElementData

    cronofyDatetimePicker.current = new CronofyElements.current.DateTimePicker({
      element_token: token,
      data_center: dataCenter,
      target_id: 'cronofy-datetime-picker',
      availability_query: {
        participants: [
          {
            required: 'all',
            members: subs.map((sub) => ({
              sub,
              managed_availability: true,
            })),
          },
        ],
        // NOTE: required_duration is in minutes, so we need to convert it from seconds
        required_duration: { minutes: requiredDuration / 60 },
        query_periods: [
          {
            start: startTime,
            end: endTime,
          },
        ],
      },
      locale: locale || currentLang,
      config: {
        mode: 'no_confirm',
        display_slot_timezone: false,
        ...config,
      },
      styles: {
        prefix: 'qasa',
        colors: CRONOFY_COLOR_OVERRIDES,
      },
      callback: (data: CronofyNotification) => {
        if (data.notification.type === 'slot_selected') {
          onSelectedSlot(data.notification.slot)
        }
      },
    })
  }, [CronofyElements, cronofyDatetimePicker, locale, currentLang, config, onSelectedSlot, elementTokenData])

  const refreshDateTimePicker = useCallback(() => {
    if (!CronofyElements.current) {
      return
    }

    if (!elementTokenData) {
      getElementToken({
        variables: {
          homeId,
          origin: window.location.origin,
        },
      })
      return
    }

    if (!cronofyDatetimePicker.current) {
      initDateTimePicker()
      return
    }

    const { token } = elementTokenData.cronofyElementData

    cronofyDatetimePicker.current.refresh({
      element_token: token,
      locale: locale || currentLang,
      config: {
        ...config,
      },
    })
  }, [
    cronofyDatetimePicker,
    locale,
    currentLang,
    config,
    initDateTimePicker,
    elementTokenData,
    homeId,
    getElementToken,
  ])

  useEffect(() => {
    import('cronofy-elements').then((module) => {
      CronofyElements.current = module
      refreshDateTimePicker()
    })

    // Instead of rerunning effect this on dependency change, we run a refresh function further down
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    refreshDateTimePicker()
  }, [config, locale, currentLang, refreshDateTimePicker, refreshKey])

  return <div id="cronofy-datetime-picker" />
}
