import {
  PlatformEnum,
  BankAccountTypeEnum,
  CurrencyEnum,
  IdentificationTypeEnum,
  MarketNameTypeEnum,
} from '@qasa/graphql'

import type { Region } from '../contexts/region'
import { getEnv, isServerStaging } from '../env'

type RegionConfig = {
  brandName: 'Blocket Bostad' | 'Qasa'
  platform?: PlatformEnum
  countryName: string | null
  market?: MarketNameTypeEnum
  currency: CurrencyEnum
  currencySymbol: string
  availableIdVerifications: IdentificationTypeEnum[]
  bankAccountTypes: BankAccountTypeEnum[]
  rootUrl: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const isBlocket = getEnv('BRAND_NAME') === 'blocket'

const DOMAINS = {
  se: {
    staging: isBlocket ? 'https://staging.bostad.blocket.se' : 'https://staging.qasa.se',
    production: isBlocket ? 'https://bostad.blocket.se' : 'https://qasa.se',
  },
  fi: {
    staging: 'https://staging.qasa.fi',
    production: 'https://qasa.fi',
  },
  fr: {
    staging: 'https://staging.qasa.fr',
    production: 'https://qasa.fr',
  },
  international: {
    staging: 'https://staging.qasa.com',
    production: 'https://qasa.com',
  },
}

export const getRootUrl = (region: Region) => {
  if (process.env.EXPO_PUBLIC_WEB_ROOT) {
    return process.env.EXPO_PUBLIC_WEB_ROOT
  }
  return DOMAINS[region][isServerStaging() ? 'staging' : 'production']
}

export const REGION_CONFIG: Record<Region, RegionConfig> = {
  se: {
    brandName: isBlocket ? 'Blocket Bostad' : 'Qasa',
    platform: isBlocket ? PlatformEnum.blocket : PlatformEnum.qasa,
    countryName: 'Sweden',
    market: MarketNameTypeEnum.sweden,
    currency: CurrencyEnum.SEK,
    currencySymbol: 'kr',
    availableIdVerifications: [IdentificationTypeEnum.se_bank_id],
    bankAccountTypes: [
      BankAccountTypeEnum.bank_account,
      BankAccountTypeEnum.international,
      BankAccountTypeEnum.bank_giro,
      BankAccountTypeEnum.plus_giro,
      BankAccountTypeEnum.address,
    ],
    rootUrl: getRootUrl('se'),
  },
  fi: {
    brandName: 'Qasa',
    platform: PlatformEnum.qasa_finland,
    countryName: 'Finland',
    market: MarketNameTypeEnum.finland,
    currency: CurrencyEnum.EUR,
    currencySymbol: '€',
    availableIdVerifications: [IdentificationTypeEnum.fi_tupas],
    bankAccountTypes: [BankAccountTypeEnum.international, BankAccountTypeEnum.address],
    rootUrl: getRootUrl('fi'),
  },
  fr: {
    brandName: 'Qasa',
    platform: PlatformEnum.qasa_france,
    countryName: 'France',
    market: MarketNameTypeEnum.france,
    currency: CurrencyEnum.EUR,
    currencySymbol: '€',
    availableIdVerifications: [IdentificationTypeEnum.onfido],
    bankAccountTypes: [BankAccountTypeEnum.international],
    rootUrl: getRootUrl('fr'),
  },
  international: {
    brandName: 'Qasa',
    countryName: null,
    currency: CurrencyEnum.EUR,
    currencySymbol: '€',
    availableIdVerifications: [
      IdentificationTypeEnum.se_bank_id,
      IdentificationTypeEnum.fi_tupas,
      IdentificationTypeEnum.no_bank_id,
      IdentificationTypeEnum.onfido,
    ],
    bankAccountTypes: [
      BankAccountTypeEnum.bank_account,
      BankAccountTypeEnum.international,
      BankAccountTypeEnum.bank_giro,
      BankAccountTypeEnum.plus_giro,
      BankAccountTypeEnum.address,
    ],
    rootUrl: getRootUrl('international'),
  },
}
