import i18Next from 'i18next'
import { enGB, sv, fi, fr } from 'date-fns/locale'
import {
  differenceInCalendarDays,
  differenceInMinutes,
  formatDistance,
  intervalToDuration,
  isBefore,
  subDays,
  format,
} from 'date-fns'
import { tz } from '@date-fns/tz'

import { getCurrentLanguage } from './i18n'

export const getLocale = () => {
  // TODO: add brand config language as a fallback
  const currentLanguage = i18Next.language
  if (!currentLanguage) return enGB
  const locale = {
    sv,
    en: enGB,
    fi,
    fr,
  }[currentLanguage]
  return locale
}

export function formatDateToYearMonthDay({ date }: { date: Date }) {
  const currentLanguage = getCurrentLanguage()
  /* Override english locale because we want to use YYYY-MM-DD instead of MM/DD/YYYY */
  const dateLocale = currentLanguage === 'en' ? 'sv' : currentLanguage
  return new Intl.DateTimeFormat(dateLocale).format(date)
}
export function formatDateToDayMonth(date: string) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'd MMMM', { locale, in: tz('UTC') })
}

export function formatDateToOrdinalDayOfMonth(date: string) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'do MMMM', { locale, in: tz('UTC') })
}

export function getDaysSince({ date }: { date: Date }) {
  return differenceInCalendarDays(new Date(), date)
}

export function subtractDays(date: Date, numOfDays: number) {
  return subDays(date, numOfDays).toISOString()
}

type FormatDateDistanceParams = {
  date: Date
  referenceDate?: Date
  shouldAddSuffix?: boolean
}
export function formatDateDistance({
  date,
  referenceDate = new Date(),
  shouldAddSuffix = false,
}: FormatDateDistanceParams) {
  const locale = getLocale()
  if (!locale) return ''
  return formatDistance(date, referenceDate, { addSuffix: shouldAddSuffix, locale })
}

export function formatDayAndMonthSimplified({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'd MMM', { locale })
}

export function formatDateToHours({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'HH', { locale })
}

export function formatDateToMinutes({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'mm', { locale })
}

export function formatDateToHourAndMinutes({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'HH:mm', { locale })
}

export function formatDateToWeekdayDayMonth(date: string) {
  const locale = getLocale()

  return new Intl.DateTimeFormat(locale?.code, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  }).format(new Date(date))
}

export function formatDateToDayOfWeek({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''
  return format(date, 'EEE', { locale })
}

export function formatToLongLocalizedDate({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''

  return format(date, 'PPP', { locale })
}

type DaysOrWeeksFormat = 'day' | 'days' | 'week' | 'weeks'
export const roundUpHoursToDaysOrWeeks = ({ hours }: { hours: number }) => {
  const hoursToDays = Math.ceil(hours / 24) || 1
  let value = hoursToDays
  let format: DaysOrWeeksFormat = hoursToDays > 1 ? 'days' : 'day'

  if (hoursToDays >= 7) {
    const daysToWeeks = Math.ceil(hoursToDays / 7) || 1
    value = daysToWeeks
    format = daysToWeeks > 1 ? 'weeks' : 'week'
  }

  return { value, format }
}

export const getHourlyCountdown = ({ countDownDate }: { countDownDate?: Date }) => {
  return countDownDate
    ? Math.max(0, Math.ceil(differenceInMinutes(countDownDate, new Date()) / 60)).toString()
    : ''
}

export const getHoursAndMinutesCountdown = ({ countDownDate }: { countDownDate?: Date }) => {
  if (!countDownDate) return null

  if (isBefore(countDownDate, new Date())) return null

  const interval = intervalToDuration({
    start: new Date(),
    end: countDownDate,
  })
  return { hours: interval.hours || 0, minutes: interval.minutes || 0 }
}

export function formatDateRange(startDate: string, endDate: string) {
  const currentLanguage = getCurrentLanguage()
  return new Intl.DateTimeFormat(currentLanguage, {
    month: 'long',
    day: 'numeric',
  }).formatRange(new Date(startDate), new Date(endDate))
}

export function formatDateToDayMonthYear({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''

  return format(date, 'd MMMM yyyy', { locale })
}

export function formatDateAndTime({ date }: { date: Date }) {
  const locale = getLocale()
  if (!locale) return ''

  return format(date, 'd MMMM yyyy HH:mm', { locale })
}
