import type { Operation } from '@apollo/client'
import { ApolloClient, createHttpLink, from, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities'

import { getEnv } from '../../env'

import { actionCableLink } from './action-cable/action-cable-link'
import {
  conversationsPagination,
  findTenantOffsetLimitPagination,
  homeOffsetLimitPagination,
  newInboxPagination,
} from './paginations'
import { authLink } from './auth-link'
import { errorLink } from './error-link'

/**
 * Apollo client assumes that all objects returned in queries have an id or _id fields.
 * When that is not the case, you need to manually set up the cache for those objects
 * Our user type id field is called "uid"
 *
 * Docs: https://www.apollographql.com/docs/react/caching/cache-configuration/#custom-identifiers
 */
const cacheBuilder = () =>
  new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          newInbox: newInboxPagination([
            'starMarked',
            'archived',
            'onlyActiveConversations',
            'searchString',
            'homeId',
          ]),
          homeApplications: relayStylePagination(),
          searchArea: { keyArgs: ['areaParams'] },
          homeSearch: { keyArgs: ['searchParams', 'order', 'orderBy'] },
          conversations: conversationsPagination(['status', 'homeId']),
          homeTemplates: relayStylePagination(),
          homes: relayStylePagination(['homeStatuses', 'searchString', 'rentalTypes']),
          tenantAds: findTenantOffsetLimitPagination(['homeId', 'offset']),
          home(_, { args, toReference }) {
            return toReference({
              __typename: 'Home',
              id: args?.id,
            })
          },
        },
      },
      User: { keyFields: ['uid'] },
      UserApplicationStats: { keyFields: ['uid'] },
      HomeSearch: { fields: { filterHomesOffset: homeOffsetLimitPagination() } },
      HomeIndexSearchQuery: { fields: { documents: homeOffsetLimitPagination() } },
      Conversation: { fields: { messages: relayStylePagination() } },
    },
  })

const httpLink = createHttpLink({
  uri: `${getEnv('SERVER_URL')}graphql`,
  credentials: 'include',
})

const requiresSubscription = (operation: Operation) => {
  const definition = getMainDefinition(operation.query)
  const isSubscription = definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  return isSubscription
}

const splitLink = split(requiresSubscription, actionCableLink, httpLink)

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  cache: cacheBuilder(),
})
