import type { ExtractRouteParams } from 'react-router'

import type { RouteName, QueryParams } from './routes'
import { routes } from './routes'
import { generatePath } from './generate-path'

export type GetPathType<TReturn> = <T extends RouteName>(
  name: T,
  params?: ExtractRouteParams<(typeof routes)[T]['path'], string>,
  queryParams?: QueryParams<T>,
) => TReturn

export const getPath: GetPathType<string> = (name, params, queryParams) =>
  generatePath({ path: routes[name].path, params, queryParams })

export const getIsLegacyRoute = <T extends RouteName>(name: T) => routes[name].isLegacyRoute
