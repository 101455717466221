import { encodeURI, decode } from 'js-base64'
import { z } from 'zod'

const callbackSchema = z.object({
  /**
   * The URL to return to after the login is completed.
   */
  returnTo: z.string().optional(),
  /**
   * The auth provider that the user will be authenticated with.
   *
   * TODO: Make this required.
   */
  provider: z.enum(['schibsted_se', 'schibsted_fi']).optional(),
  /**
   * User properties to be set on the user if a new account is created.
   */
  userProperties: z
    .object({
      companyName: z.string().nullish(),
      landlord: z.boolean().nullish(),
      orgNumber: z.string().nullish(),
      professional: z.boolean().nullish(),
    })
    .optional(),
})

export type AuthCallbackState = z.infer<typeof callbackSchema>

/**
 * Encodes an AuthCallbackState object into a URL-safe string.
 *
 * The encoded string can be passed as a query parameter to the login endpoint.
 */
export const encodeAuthCallbackState = (params: AuthCallbackState) => {
  return encodeURI(JSON.stringify(params))
}

/**
 * Decodes a base64 encoded string into an AuthCallbackState object.
 *
 * Throws if the input cannot be parsed as a valid AuthCallbackState object.
 */
export const decodeAuthCallbackState = (value: string): AuthCallbackState => {
  return callbackSchema.parse(JSON.parse(decode(value)))
}
