import { useHistory } from 'react-router'
import { useRouter } from 'next/navigation'

import { getCurrentLanguage } from '../utils/i18n'

import type { GetPathType } from './get-path'
import { getIsLegacyRoute, getPath } from './get-path'

export const useLinkTo = () => {
  const { push } = useHistory()
  const router = useRouter()
  const locale = getCurrentLanguage()

  const linkTo: GetPathType<void> = (to, params, queryParams) => {
    const isLegacyRoute = getIsLegacyRoute(to)

    if (isLegacyRoute) {
      push(getPath(to, params, queryParams))
    } else {
      router.push(`/${locale}${getPath(to, params, queryParams)}`)
    }
  }

  /**
   * linkToUnsafe can be used to link to any path in p2, but without type safety. If possible, prefer linkTo.
   */
  const linkToUnsafe = (to: string) => router.push(`/${locale}${to}`)

  return { linkTo, linkToUnsafe }
}
