import Cookies from 'js-cookie'

import { P1 } from './p1-api'

const BASIC_AUTHENTICATION_SEPARATOR = ':'

export function getP1AccessToken() {
  return Cookies.get('Access-Token')
}

export function setP1AccessToken(token?: string) {
  if (token) {
    Cookies.set('Access-Token', token, { expires: 30 })
  } else {
    Cookies.remove('Access-Token')
  }
  P1.updateHeaders({ [P1.ACCESS_TOKEN_HEADER_NAME]: token ?? '' })
}

export function getAdminToken() {
  return Cookies.get('AdminToken')
}

export function setAdminToken(token?: string) {
  if (token) {
    Cookies.set('AdminToken', token)
    P1.updateHeaders({ [P1.ACCESS_TOKEN_HEADER_NAME]: token })
  } else {
    P1.updateHeaders({ [P1.ACCESS_TOKEN_HEADER_NAME]: getP1AccessToken() ?? '' })
    Cookies.remove('AdminToken')
  }
}

export function encodeBasicAuthenticationToken(email: string, password: string) {
  return window.btoa(email + BASIC_AUTHENTICATION_SEPARATOR + password)
}
